import type * as Stitches from '@stitches/react';
import Link from 'next/link';
import { readableColor } from 'polished';
import { styled } from '@/components/content/stitches';
import { useConfigOverride } from '@/redux/cmsConfig';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import { safePolished } from '@/utils/safePolished';

interface Props {
  style: string;
  label: string;
  href?: string;
  newWindow?: boolean;
  type?: 'local' | 'external' | 'attachment';
}

export function Button({
  style = 'primary',
  href = '#',
  label,
  newWindow = false,
  type = 'local',
}: Props) {
  const {
    primary_font,
    secondary_font,
    knockout_font,
    primary_button_background_color,
    primary_button_border_radius,
    primary_button_border_color,
    primary_button_background_hover_color,
    primary_button_border_hover_color,
    primary_button_text_hover_color,
    secondary_button_background_color,
    secondary_button_border_radius,
    secondary_button_border_color,
    secondary_button_background_hover_color,
    secondary_button_border_hover_color,
    secondary_button_text_hover_color,
    knockout_button_background_color,
    knockout_button_border_radius,
    knockout_button_border_color,
    knockout_button_background_hover_color,
    knockout_button_border_hover_color,
    knockout_button_text_hover_color,
  } = useConfigOverride('buttons');

  const { field_height, font } = useConfigOverride('forms');

  function getButtonTheme(value: string) {
    let values: Stitches.CSS = {
      height: `${field_height}px`,
      ...getFontStyles(font, ['font_size']),
    };

    switch (value) {
      case 'primary':
        values = {
          ...values,
          background: primary_button_background_color,
          borderRadius: `${primary_button_border_radius}px`,
          border: `1px solid ${primary_button_border_color}`,
          ...getFontStyles(primary_font, ['color', 'font_family', 'font_style', 'line_height'], {
            color: (value) => value ?? safePolished(readableColor)(primary_button_background_color),
          }),

          '&:hover': {
            background: primary_button_background_hover_color,
            borderColor: primary_button_border_hover_color,
            color: primary_button_text_hover_color,
          },
        };
        break;
      case 'secondary':
        values = {
          ...values,
          background: secondary_button_background_color,
          borderRadius: `${secondary_button_border_radius}px`,
          border: `1px solid ${secondary_button_border_color}`,
          ...getFontStyles(secondary_font, ['color', 'font_family', 'font_style', 'line_height'], {
            color: (value) =>
              value ?? safePolished(readableColor)(secondary_button_background_color),
          }),

          '&:hover': {
            background: secondary_button_background_hover_color,
            borderColor: secondary_button_border_hover_color,
            color: secondary_button_text_hover_color,
          },
        };
        break;
      case 'tertiary':
        values = {
          ...values,
          background: knockout_button_background_color,
          borderRadius: `${knockout_button_border_radius}px`,
          border: `1px solid ${knockout_button_border_color}`,
          ...getFontStyles(knockout_font, ['color', 'font_family', 'font_style', 'line_height'], {
            color: (value) => value ?? knockout_button_border_color,
          }),

          '&:hover': {
            background: knockout_button_background_hover_color,
            borderColor: knockout_button_border_hover_color,
            color: knockout_button_text_hover_color,
          },
        };
        break;
      default:
        break;
    }

    return values;
  }

  return (
    <Link href={type === 'local' ? `/${href}` : href} passHref legacyBehavior>
      <Element
        data-css-override="StyledPrimaryButton"
        target={newWindow || type !== 'local' ? '_blank' : '_self'}
        css={getButtonTheme(style)}
      >
        {label}
      </Element>
    </Link>
  );
}

const Element = styled('a', {
  alignItems: 'center',
  display: 'flex',
  fontWeight: 'normal',
  justifyContent: 'center',
  lineHeight: 'normal',
  minWidth: '100px',
  overflow: 'hidden',
  padding: '0 $5',
  position: 'relative',
  textAlign: 'center',
  textDecoration: 'none',
  transition: 'all .15s ease',
});
