import { Button } from './button';
import { Image as ImageModule } from './image';
import { Text } from './text';
import * as Types from './types';
import { getAlignmentVariants } from '@/components/content/fields/alignment/utils';
import { Iframe } from '@/components/content/modules/iframe/iframe';
import { getBackgroundStyles } from '@/components/content/modules/utils';
import { styled } from '@/components/content/stitches';

interface Props {
  content: {
    order: string[];
    modules: Record<
      string,
      Types.Button | Types.Text | Types.Image | Types.Container | Types.Iframe
    >;
  };
  layout: Types.LayoutField;
}

export function Container({ content, layout }: Props) {
  const hasImage = Boolean(layout?.backgroundImage?.url);

  return (
    <Wrapper
      className="module-container--wrapper"
      css={{
        ...getBackgroundStyles(
          layout?.backgroundImage,
          layout?.mobileBackgroundImage,
          layout?.backgroundColor,
        ),
      }}
    >
      {hasImage && <Overlay />}

      <Element
        hasImage={hasImage}
        alignment={layout?.alignment ?? 'center-center'}
        direction={layout?.direction ?? 'vertical'}
      >
        {content.order.map((id: string) => {
          const moduleItem = content.modules[id];

          switch (moduleItem.type) {
            case Types.Modules.container:
              return <Container key={id} {...moduleItem} />;
            case Types.Modules.text:
              return <Text key={id} {...moduleItem} />;
            case Types.Modules.image:
              return <ImageModule key={id} {...moduleItem} />;
            case Types.Modules.button:
              return <Button key={id} {...moduleItem.button} />;
            case Types.Modules.iframe:
              return <Iframe key={id} {...moduleItem} />;

            default:
              return null;
          }
        })}
      </Element>
    </Wrapper>
  );
}

const Wrapper = styled('section', {
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'grid',
});

const Overlay = styled('div', {
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  gridColumn: '1 / -1',
  gridRow: '1 / -1',
  zIndex: '$0',
});

const Element = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$8',
  maxWidth: '$4xl',
  margin: '0 auto',
  padding: '$12',
  width: '100%',
  zIndex: '$10',

  variants: {
    hasImage: {
      true: {
        minHeight: '$128',
      },
    },
    direction: {
      horizontal: {
        flexDirection: 'row',
      },
      vertical: {
        flexDirection: 'column',
      },
    },
    // you need to define all variants here to use them, even though we use them in the compoundVariants returned from `getAlignmentVariants`
    alignment: {
      'left-top': {},
      'center-top': {},
      'right-top': {},
      'left-center': {},
      'center-center': {},
      'right-center': {},
      'left-bottom': {},
      'center-bottom': {},
      'right-bottom': {},
    },
  },

  compoundVariants: getAlignmentVariants(),
});
