import { styled } from '@stitches/react';

interface Props {
  aspectRatio: 'none' | 'video' | 'document' | 'fill';
  source: string;
  maxWidth: number;
}

export function Iframe({ source, maxWidth, aspectRatio }: Props) {
  return (
    <Wrapper
      css={{
        maxWidth: Boolean(maxWidth) ? `${maxWidth}px` : 'none',
      }}
    >
      <Frame aspectRatio={aspectRatio} src={source} />
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  margin: '0 auto',
  width: '100%',
});

const Frame = styled('iframe', {
  border: 0,
  width: '100%',

  variants: {
    aspectRatio: {
      none: {
        aspectRatio: 'auto',
      },
      video: {
        aspectRatio: '16 / 9',
      },
      document: {
        aspectRatio: '1 / 1.414',
      },
      fill: {
        height: 'calc(100vh - $20)',
        maxWidth: 'none',
      },
    },
  },
});
