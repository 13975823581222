import { formatRichText } from './elements';
import { type BlockObject, type ListObject, type ListItemObject, type Elements } from './types';
import { styled } from '@/components/content/stitches';

interface Props {
  value: string;
  tag?: Elements.paragraph | Elements.heading | Elements.subheading;
}

export function RichText({ value }: Props) {
  const parsedValue = JSON.parse(value !== '' ? value : '[]') as unknown as
    | BlockObject[]
    | ListObject[]
    | ListItemObject[];

  return <Container>{parsedValue.map((item, index) => formatRichText(item, index))}</Container>;
}

const Container = styled('div', {
  margin: 0,
  maxWidth: '$2xl',
});
