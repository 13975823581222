import Head from 'next/head';
import { createContext } from 'react';
import { type ModuleTypes } from './modules/types';
import Layout from '@/components/app/layout';
import { Content } from '@/components/content/content';

interface Props {
  id: string;
  content: {
    order: string[];
    modules: Record<string, ModuleTypes>;
  };
  seoTitle?: string;
  seoDescription?: string;
  seoImage?: string;
}

export const PageContext = createContext('');

export function Page({ id, content, seoTitle, seoDescription, seoImage }: Props) {
  return (
    <>
      <Head>
        {seoTitle && (
          <>
            <title>{seoTitle}</title>
            <meta property="og:title" content={seoTitle} key="og:title" />
            <meta name="twitter:title" content={seoTitle} key="twitter:title" />
          </>
        )}

        {seoDescription && (
          <>
            <meta name="description" content={seoDescription} key="description" />
            <meta property="og:description" content={seoDescription} key="og:description" />
            <meta name="twitter:description" content={seoDescription} key="twitter:description" />
          </>
        )}

        {seoImage && (
          <>
            <meta property="og:image" content={seoImage} key="og:image" />
            <meta name="twitter:image" content={seoImage} key="twitter:image" />
          </>
        )}
      </Head>

      <Layout>
        <PageContext.Provider value={id}>
          <Content content={content} />
        </PageContext.Provider>
      </Layout>
    </>
  );
}
