import { Link } from './link';
import type * as Types from '@/components/content/modules/types';
import { Stack } from '@/components/uielements/stack/stack';

interface Props {
  content: {
    order: string[];
    modules: Record<string, Types.Link>;
  };
}

export function LinkList({ content }: Props) {
  return (
    <Stack>
      {content.order.map((id) => {
        const moduleItem = content.modules[id];

        return <Link key={id} {...moduleItem} />;
      })}
    </Stack>
  );
}
