import { type ImageField, type UrlField } from '@/components/content/modules/types';
import { styled } from '@/components/content/stitches';
import { resizeImage } from '@/utils/imageHelper';

interface Props {
  image: ImageField;
  url: UrlField;
}

export function Image({ image, url }: Props) {
  const { reference, label } = url;
  const imageComponent = (
    <Element src={resizeImage(image?.url, { width: 1500 })} alt={image?.title} />
  );

  if (reference?.url) {
    return (
      <a href={reference.url} title={label}>
        {imageComponent}
      </a>
    );
  }

  return imageComponent;
}

const Element = styled('img', {
  height: 'auto',
  maxHeight: '500px',
  objectFit: 'cover',
  width: '100%',
});
