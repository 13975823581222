import { useEffect, useState } from 'react';
import type * as Types from '../types';
import { Controls } from '@/components/content/modules/slider/controls';
import { Slide } from '@/components/content/modules/slider/slide';
import { styled } from '@/components/content/stitches';
import { type Timeout } from '@/types/timeout';

interface Props {
  content: {
    order: string[];
    modules: Record<string, Types.Slide>;
  };
  autoplay: boolean;
  interval: number;
}

export function Slider({ content, autoplay = true, interval = 5 }: Props) {
  // @TODO: replace one-off slider functionality with the `useSlider` hook
  const [active, setActive] = useState(0);
  const [shouldAutoplay, setShouldAutoplay] = useState(autoplay);
  const timeout = Number(interval) * 1000;
  let timer: Timeout;

  useEffect(
    function () {
      if (shouldAutoplay) {
        timer = setTimeout(() => {
          setActive(active === content.order.length - 1 ? 0 : active + 1);
        }, timeout);

        return function () {
          clearTimeout(timer);
        };
      } else {
        clearTimeout(timer);
      }
    },
    [active, shouldAutoplay],
  );

  return (
    <Wrapper>
      <Track>
        {content.order.map((id) => {
          const moduleItem = content.modules[id];

          return (
            <Item
              css={{
                transform: `translate3d(${active * -100}%, 0, 0)`,
              }}
              key={id}
            >
              <Slide key={id} {...moduleItem} />
            </Item>
          );
        })}
      </Track>

      <Controls
        active={active}
        items={content.order}
        showAutoPlayControls={autoplay}
        isAutoPlaying={shouldAutoplay}
        onChange={(value) => {
          setActive(value);
          setShouldAutoplay(false);
        }}
        onPlayStateChange={() => setShouldAutoplay(!shouldAutoplay)}
      />
    </Wrapper>
  );
}

const Wrapper = styled('section', {
  position: 'relative',
});

const Track = styled('div', {
  display: 'flex',
  overflow: 'hidden',
});

const Item = styled('div', {
  flex: 'none',
  transition: 'all .4s ease',
  width: '100%',
  zIndex: '$0',
});
