import { useRouter } from 'next/router';
import { styled } from '@/components/content/stitches';
import { ROUTES } from '@/constants/routes';
import { usePopularItems } from '@/features/popular-items/service';
import { Widget } from '@/features/popular-items/widget';
import { useSelector } from '@/redux';
import { useConfigOverride } from '@/redux/cmsConfig';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import { setSelectedPopularItem } from '@/services/recommendations.service';

interface Props {
  title: string;
}

export function PopularItems({ title }: Props) {
  const router = useRouter();

  const { flagship_store_location } = useSelector((state) => state.app.cmsConfig.webConfig.menu);

  const { font } = useConfigOverride('menu_category_header');

  const { response, enabled, getItem, count } = usePopularItems({
    location: flagship_store_location,
  });

  if (!enabled || response.status !== 'success') {
    return null;
  }

  return (
    <Container>
      <Widget
        count={count}
        items={response.data?.products.map(getItem) ?? []}
        onClick={(item) => {
          setSelectedPopularItem(item.id);

          void router.push(ROUTES.LOCATIONS);
        }}
      >
        <Title
          style={{
            ...getFontStyles(font),
          }}
        >
          {title}
        </Title>
      </Widget>
    </Container>
  );
}

const Container = styled('div', {
  margin: '0 auto',
  maxWidth: '$7xl',
  padding: '$6 $3',

  '@md': {
    padding: '$12',
  },
});

const Title = styled('h2', {
  margin: 0,
});
