import { type ReactNode } from 'react';

export enum Elements {
  // @TODO: update brand cms type to blockItem. This is a generic that can be displayed as a <p> or <h2> tag
  'inline' = 'text',
  'blockItem' = 'paragraph',
  'orderedList' = 'ol',
  'unorderedList' = 'ul',
  'paragraph' = 'p',
  'heading' = 'h2',
  'subheading' = 'h3',
  'listItem' = 'li',
  'anchor' = 'a',
}

export type size = 'small' | 'normal' | 'large' | 'x-large';

export interface TextObject {
  type: Elements.inline;
  text: string | ReactNode;
  bold?: boolean;
  italic?: boolean;
  size?: size;
  color?: string;
}

export interface BlockObject {
  type: Elements.blockItem;
  children: TextObject[] | AnchorObject[];
}

export interface AnchorObject {
  type: Elements.anchor;
  href: string;
  target: string;
  children: TextObject[];
}

export interface ListItemObject {
  type: Elements.listItem;
  children: TextObject[] | AnchorObject[];
}

export interface ListObject {
  type: Elements.unorderedList | Elements.orderedList;
  children: ListItemObject[];
}
