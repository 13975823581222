import { contact } from '@koala/sdk/v4';
import { Formik, Form } from 'formik';
import { useContext, useState } from 'react';
import { RichText } from '@/components/content/fields/richtext/richtext';
import { Elements } from '@/components/content/fields/richtext/types';
import { Locations } from '@/components/content/modules/form/locations';
import { Message } from '@/components/content/modules/form/message';
import { Field } from '@/components/content/modules/form/text';
import * as Types from '@/components/content/modules/types';
import { PageContext } from '@/components/content/page';
import { styled } from '@/components/content/stitches';
import { Modal } from '@/components/uielements/modal';
import { StyledPrimaryButton } from '@/components/uielements/primaryButton/styles';
import { Stack } from '@/components/uielements/stack/stack';
import { useGlobalDispatch } from '@/redux/global/reducer';
import { createHttpClient } from '@/services/client';
import { getOrigin } from '@/utils';
import { prepareErrorMessage } from '@/utils/global';

interface Props {
  id: string;
  content: {
    order: string[];
    modules: Record<
      string,
      Types.FormText | Types.FormMessage | Types.FormEmail | Types.FormPhone | Types.FormLocations
    >;
  };
  title: Types.RichtextField;
  description: Types.RichtextField;
  subject: string;
  email: string;
  label: string;
}

export function FormWrapper({ id, content, title, description, label }: Props) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { dispatch, displayErrorToast } = useGlobalDispatch();
  const pageId = useContext(PageContext);
  const initialValues: Record<string, string> = {};

  content.order.forEach((id) => {
    const field = content.modules[id];

    initialValues[field.name] = '';
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { resetForm }) => {
        if (loading) {
          return;
        }

        setLoading(true);

        try {
          const client = createHttpClient({
            origin: getOrigin(window.location.host),
          });

          await contact({ module_id: id, page_id: pageId, ...values }, { client });

          setSuccess(true);
          resetForm();
        } catch (e) {
          const errorMessage = await prepareErrorMessage('Something went wrong.', e);
          dispatch(displayErrorToast(errorMessage.message));
        } finally {
          setLoading(false);
        }
      }}
    >
      <Container>
        <RichText value={title} tag={Elements.heading} />
        <RichText value={description} tag={Elements.paragraph} />

        <Stack
          overrides={{ marginTop: 'var(--size-4)' }}
          direction="vertical"
          align="trailing"
          gap="var(--size-4)"
        >
          {content.order.map((id) => {
            const moduleItem = content.modules[id];

            switch (moduleItem.type) {
              case Types.Modules.formText:
                return <Field key={id} {...moduleItem} />;
              case Types.Modules.formEmail:
                return <Field key={id} {...moduleItem} type="email" />;
              case Types.Modules.formPhone:
                return <Field key={id} {...moduleItem} type="tel" />;
              case Types.Modules.formMessage:
                return <Message key={id} {...moduleItem} />;
              case Types.Modules.formLocations:
                return <Locations key={id} {...moduleItem} />;

              default:
                return null;
            }
          })}

          <StyledPrimaryButton data-css-override="StyledPrimaryButton" type="submit">
            {loading ? 'Sending...' : label}
          </StyledPrimaryButton>
        </Stack>

        <Modal.Container requestVisibility={success} onCancel={() => setSuccess(false)}>
          <>
            <Modal.Title></Modal.Title>

            <Modal.Content>
              <p>Thanks, your message has been sent!</p>
            </Modal.Content>

            <Modal.Footer>
              <Modal.Cancel>Close</Modal.Cancel>
            </Modal.Footer>
          </>
        </Modal.Container>
      </Container>
    </Formik>
  );
}

const Container = styled(Form, {
  margin: '0 auto',
  maxWidth: '100%',
  padding: '$4',
  width: '$2xl',

  '@md': {
    padding: '$12',
  },
});
