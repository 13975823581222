export type RichtextField = string;

export type ColumnField = 'one' | 'two' | 'three' | 'four';

export interface ImageField {
  id?: number;
  url?: string;
  title?: string;
}

export interface UrlField {
  label: string;
  reference?: {
    type: 'local' | 'external' | 'attachment';
    label: string;
    url: string;
    newWindow: boolean;
  };
}

export interface ButtonField {
  style: string;
  url: UrlField;
}

type AlignmentField =
  | 'left-top'
  | 'center-top'
  | 'right-top'
  | 'left-center'
  | 'center-center'
  | 'right-center'
  | 'left-bottom'
  | 'center-bottom'
  | 'right-bottom';

type DirectionField = 'horizontal' | 'vertical';

export type TypographyField = 'primary' | 'secondary';

export interface LayoutField {
  alignment?: AlignmentField;
  backgroundColor?: string;
  backgroundImage?: ImageField;
  mobileBackgroundImage?: ImageField;
  direction?: DirectionField;
  height?: string;
}

export interface Text {
  type: Modules.text;
  body: RichtextField;
}

export interface Image {
  type: Modules.image;
  image: ImageField;
  url: UrlField;
}

export interface Button {
  type: Modules.button;
  button: ButtonField;
}

interface Form {
  type: Modules.form;
  content: {
    order: string[];
    modules: Record<string, FormText | FormMessage | FormEmail | FormPhone | FormLocations>;
  };
  title: RichtextField;
  description: RichtextField;
  subject: string;
  email: string;
  label: string;
}

export interface FormField {
  label: string;
  name: string;
  required: boolean;
}

export type FormText = {
  type: Modules.formText;
} & FormField;

export type FormMessage = {
  type: Modules.formMessage;
} & FormField;

export type FormEmail = {
  type: Modules.formEmail;
} & FormField;

export type FormPhone = {
  type: Modules.formPhone;
} & FormField;

export type FormLocations = {
  type: Modules.formLocations;
} & FormField;

export interface Container {
  type: Modules.container;
  content: {
    order: string[];
    modules: Record<string, Button | Image | Text | Container | Iframe>;
  };
  layout: LayoutField;
}

interface Column {
  type: Modules.column;
  content: {
    order: string[];
    modules: Record<string, Button | Image | Text | Container>;
  };
  layout: LayoutField;
}

interface Columns {
  type: Modules.columns;
  content: {
    order: string[];
    modules: Record<string, Container>;
  };
  columns: ColumnField;
  layout: LayoutField;
}

interface Slider {
  type: Modules.slider;
  content: {
    order: string[];
    modules: Record<string, Slide>;
  };
  autoplay: boolean;
  interval: number;
}

interface Hero {
  type: Modules.hero;
  layout: LayoutField;
  content: RichtextField;
  cta: ButtonField;
}

export interface Slide {
  type: Modules.slide;
  layout: LayoutField;
  content: RichtextField;
  cta: ButtonField;
}

export interface Iframe {
  type: Modules.iframe;
  aspectRatio: 'video' | 'document' | 'fill';
  source: string;
  maxWidth: number;
}

interface LinkList {
  type: Modules.linkList;
  content: {
    order: string[];
    modules: Record<string, Link>;
  };
}

export interface Link {
  type: Modules.link;
  url: UrlField;
  color: string;
  fontStyle: TypographyField;
}

interface PopularItems {
  type: Modules.popularItems;
  title: string;
}

export type ModuleTypes =
  | Button
  | Column
  | Columns
  | Container
  | Form
  | FormEmail
  | FormLocations
  | FormMessage
  | FormPhone
  | FormText
  | Hero
  | Iframe
  | Image
  | Link
  | LinkList
  | PopularItems
  | Slide
  | Slider
  | Text;

export enum Modules {
  'button' = 'button',
  'column' = 'column',
  'columns' = 'columns',
  'container' = 'container',
  'form' = 'form',
  'formEmail' = 'formEmail',
  'formLocations' = 'formLocations',
  'formMessage' = 'formMessage',
  'formPhone' = 'formPhone',
  'formText' = 'formText',
  'hero' = 'hero',
  'iframe' = 'iframe',
  'image' = 'image',
  'link' = 'link',
  'linkList' = 'linkList',
  'popularItems' = 'popularItems',
  'slide' = 'slide',
  'slider' = 'slider',
  'text' = 'text',
}
