import { type Location } from '@koala/sdk';
import { useField } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@/components/ui';
import { stateAbbrMappings, canadianProvinceAbbrMappings } from '@/constants/states';
import { locationsActions } from '@/redux/locations/actions';
import { locationsSliceSelector } from '@/redux/locations/reducer';
import { genericGroupItemsByKey } from '@/utils/global';
import { splitLocationsByCountry } from '@/utils/locations';
import { MagicBoxParams } from '@/utils/magicBox';

interface Props {
  label: string;
  name: string;
  required: boolean;
}

export function Locations({ label, name, required }: Props) {
  const [field, meta] = useField({ name, required });

  const magicBox = new MagicBoxParams()
    .setPagination(1, 100)
    .setSorts({ state_id: 'asc', label: 'asc' })
    .setIncludes(['label']);

  const { list } = useSelector(locationsSliceSelector);
  const dispatch = useDispatch();

  // location organization
  const locationsByCountry = splitLocationsByCountry(list ?? []);

  // Group US locations by state and sort alphabetically.
  const { filtersArray: statesArray, filtersObject: statesObject } = genericGroupItemsByKey(
    locationsByCountry.US,
    'cached_data.state',
  );

  // Group CA locations by province and sort alphabetically.
  const { filtersArray: provincesArray, filtersObject: provincesObject } = genericGroupItemsByKey(
    locationsByCountry.CA,
    'cached_data.state',
  );

  useEffect(() => {
    dispatch(locationsActions.fetchAllLocations(magicBox));
  }, []);

  function createOption(item: unknown, index: number, mapping: unknown, refObject: unknown) {
    // The group of locations is uniquely identified by the state name.
    // @ts-expect-error `state` string can't index `stateAbbrMappings`.
    const stateName = mapping[item];

    /** @ts-expect-error `state` can't index `statesObject`. */
    const locations = refObject[item].map((location: Location) => (
      <option key={location.id} value={location.label}>
        {location.label}
      </option>
    ));

    return (
      <optgroup key={index} label={stateName}>
        {locations}
      </optgroup>
    );
  }

  return (
    <Form.Select
      {...field}
      label={label}
      name={name}
      required={required}
      error={meta.touched && meta.error ? meta.error : undefined}
    >
      <option>— Select a location —</option>
      {statesArray.map((item, index) => createOption(item, index, stateAbbrMappings, statesObject))}

      {provincesArray.map((item, index) =>
        createOption(item, index, canadianProvinceAbbrMappings, provincesObject),
      )}
    </Form.Select>
  );
}
