import { type RichtextField, type ButtonField, type LayoutField } from '../types';
import { getBackgroundStyles } from '../utils';
import { getAlignmentVariants } from '@/components/content/fields/alignment/utils';
import { Button } from '@/components/content/fields/button/button';
import { RichText } from '@/components/content/fields/richtext/richtext';
import { styled } from '@/components/content/stitches';

interface Props {
  layout: LayoutField;
  content: RichtextField;
  cta: ButtonField;
}

export const Hero = ({ layout, content, cta }: Props) => {
  const hasImage = Boolean(layout?.backgroundImage?.url);
  const { reference, label: buttonLabel } = cta.url;

  return (
    <Container
      hasImage={hasImage}
      css={{
        ...getBackgroundStyles(
          layout?.backgroundImage,
          layout?.mobileBackgroundImage,
          layout?.backgroundColor,
        ),
      }}
    >
      {hasImage && <Overlay />}

      <Element
        alignment={layout?.alignment ?? 'center-center'}
        direction={layout?.direction ?? 'vertical'}
      >
        <RichText value={content} />

        {Boolean(reference?.url) && (
          <Button
            style={cta.style}
            href={reference?.url}
            newWindow={reference?.newWindow}
            label={buttonLabel}
            type={reference?.type}
          />
        )}
      </Element>
    </Container>
  );
};

const Container = styled('div', {
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'grid',
  minHeight: '$128',

  // when a module has a background image, it's common for the text to be white
  // this can still be overridden by each text field and module's color value
  variants: {
    hasImage: {
      true: {
        color: 'white',
      },
      false: {
        color: 'inherit',
      },
    },
  },
});

const Overlay = styled('div', {
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  gridColumn: '1 / -1',
  gridRow: '1 / -1',
  zIndex: '$0',
});

const Element = styled('div', {
  display: 'flex',
  gridColumn: '1 / -1',
  gridRow: '1 / -1',
  height: '100%',
  padding: '$12',
  zIndex: '$10',

  variants: {
    direction: {
      horizontal: {
        flexDirection: 'row',
      },
      vertical: {
        flexDirection: 'column',
      },
    },
    // you need to define all variants here to use them, even though we use them in the compoundVariants returned from `getAlignmentVariants`
    alignment: {
      'left-top': {},
      'center-top': {},
      'right-top': {},
      'left-center': {},
      'center-center': {},
      'right-center': {},
      'left-bottom': {},
      'center-bottom': {},
      'right-bottom': {},
    },
  },

  compoundVariants: getAlignmentVariants(),
});
